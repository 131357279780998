import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {environment} from "../../environments/environment";
import {Order, Service, User} from "../interfaces";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [
    NgIf,
    MatTableModule,
    MatTableResponsiveModule,
  ],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit{
  state: 0 | 1 | 2 = 0;
  baseUrl: string = environment.baseUrl;
  registeredServices: Service[] = [];
  displayedColumns: string[] = ['no', 'name', 'type'];
  displayedColumns2: string[] = ['no', 'name', 'type', 'action'];
  userId: any = localStorage.getItem('userId');
  services!: MatTableDataSource<Service>;
  isFirst: boolean = true;
  isPackage: boolean = false;

  constructor(private http: HttpClient,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const param = `${this.route.snapshot.queryParamMap.get('state')}`;
    if(+param === 1 ){
      this.state = 1;
    } else {
      this.state = 2;
    }
    this.http.get<User>(this.baseUrl + `user/${this.userId}`)
      .subscribe((data) => {
        if (data) {
          this.registeredServices = data.services;
          this.http.get<Service[]>(this.baseUrl + `services`)
            .subscribe((data) => {
              if (data) {
                const temp: Service[] = []
                data.forEach((service) => {
                  const exists = this.registeredServices.some(reg =>
                    reg.id === service.id
                  );
                  if (!exists) {
                    temp.push(service);
                  }
                });
                this.services = new MatTableDataSource<Service>(temp);
              }
            });
        }
      });
    if (!this.isFirst) {
      this.state = 1
    }
  }

  placeOrder(service: Service) {
    if (service.name === 'Operation Package' || service.name === 'Full Tier Package') {
      this.isPackage = true;
    }
    this.http.post<Order>(this.baseUrl + 'order',  {
      title: service.name, isPackage: this.isPackage, service, userId: +this.userId
    }).subscribe((data) => {
      if (data) {
        this.isFirst = false;
        this.ngOnInit();
      }
    })
  }

}

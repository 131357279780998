import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Order} from "../interfaces";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    MatTableModule,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    MatMenuModule,
    TitleCasePipe,
    NgClass,
    RouterLink,
    DatePipe,
    MatTableResponsiveModule,
    MatPaginatorModule,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  search!: string;
  filter: string = 'all';
  displayedColumns: string[] = ['position', 'title', 'user', 'date', 'package', 'status', 'more'];
  dataSource!: MatTableDataSource<Order>;
  filteredDataSource!: MatTableDataSource<Order>;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const userId = `${this.activatedRoute.snapshot.paramMap.get('userId')}`;
    if (userId === 'null') {
      this.http.get<Order[]>(this.baseUrl + `order`)
        .subscribe((data) => {
          this.handleIncomingData(data);
        })
    } else {
      this.http.get<Order[]>(this.baseUrl + `order/user/${userId}`)
        .subscribe((data) => {
          this.handleIncomingData(data);
      })
    }
  }

  @ViewChild('order') paginator!: MatPaginator;

  ngAfterViewInit() {
    this.filteredDataSource.paginator = this.paginator;
  }


  handleIncomingData (data: Order []) {
    if (data.length) {
      data.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;
        return 0;
      });
      this.dataSource = new MatTableDataSource(data);
      this.filteredDataSource = this.dataSource;
    }
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.user.firstName.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<Order>(temp);
  }

  onFilter() {
    let temp = this.dataSource.filteredData.filter((data) => {
      if(this.filter === 'all') {
        return data;
      } else {
        return data?.status.toLowerCase().includes(this.filter);
      }
    });
    this.filteredDataSource = new MatTableDataSource<Order>(temp);
  }

  selectOrder(order: Order) {
    localStorage.setItem('order', JSON.stringify(order));
    this.router.navigate(['/', 'orders', order.id]).then(()=> {return;});
  }

}

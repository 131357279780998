import {Component} from '@angular/core';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {FormsModule} from "@angular/forms";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {TitleCasePipe} from "@angular/common";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";

@Component({
  selector: 'app-financials',
  standalone: true,
  imports: [MatButtonToggleModule, FormsModule, MatIconModule, MatIconButton, MatTableModule, TitleCasePipe, MatTableResponsiveModule],
  templateUrl: './financials.component.html',
  styleUrl: './financials.component.scss'
})
export class FinancialsComponent {
  revenueFilter: string = 'yearly';
  itemsFilter: string = 'yearly';

  transactions = [
    { title: 'LLC Registration', user: 'Ameen', phone: '+251939881843', bought: 8, amount: 200},
    { title: 'LLC Registration', user: 'Ameen', phone: '+251939881843', bought: 8, amount: 200}
  ];

  filter: string = 'all';
  search!: string;

  displayedColumns: string[] = ['icon', 'title', 'user', 'phone', 'bought', 'amount', 'action'];
  dataSource = new MatTableDataSource(this.transactions);


}

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {User} from "../interfaces";
import {environment} from "../../environments/environment";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {NgIf} from "@angular/common";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    MatTableModule,
    ReactiveFormsModule,
    FormsModule,
    MatIcon,
    MatIconButton,
    RouterLink,
    MatTableResponsiveModule,
    MatPaginatorModule,
    NgIf
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent implements OnInit, AfterViewInit {

  baseUrl: string = environment.baseUrl;
  search!: string;
  isSuperAdmin: boolean = JSON.parse(`${localStorage.getItem('isSuper')}`);
  dataSource!: MatTableDataSource<User>;
  adminDataSource!: MatTableDataSource<User>;
  filteredDataSource!: MatTableDataSource<User>;
  displayedColumns: string[] = ['id', 'name', 'phone', 'bought', 'regDate', 'action', /*'more'*/];
  displayedAdminColumns: string[] = ['id', 'name', 'phone', 'regDate', 'action', /*'more'*/];

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<User[]>(this.baseUrl + `user`)
      .subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          this.filteredDataSource = this.dataSource;
        }
      });
    if (this.isSuperAdmin) {
      this.http.get<User[]>(this.baseUrl + `user/admins`)
        .subscribe((data) => {
          if (data) {
            this.adminDataSource = new MatTableDataSource(data);
          }
        });
    }
  }

  @ViewChild('admin') adminPaginator!: MatPaginator;
  @ViewChild('user') userPaginator!: MatPaginator;

  ngAfterViewInit() {
    this.adminDataSource.paginator = this.adminPaginator;
    this.filteredDataSource.paginator = this.userPaginator;
  }

  onInputChange() {
    let temp = this.dataSource.filteredData.filter((data) => {
      return data?.firstName.toLowerCase().includes(this.search.toLowerCase());
    })
    this.filteredDataSource = new MatTableDataSource<User>(temp);
  }
}

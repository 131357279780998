<div class="financials">
  <div class="container">
    <div class="card">
      <div class="filters">
        <p class="filter-text">Service Sold</p>
        <mat-button-toggle-group
           [hideSingleSelectionIndicator]="true" [(ngModel)]="itemsFilter"
           name="items filter" aria-label="time stamp">
          <mat-button-toggle value="yearly">Yearly</mat-button-toggle>
          <mat-button-toggle value="monthly">Monthly</mat-button-toggle>
          <mat-button-toggle value="daily">Daily</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="stats">
        <div>
          <p class="amount">{{150}}</p>
          <p class="description">Total Sales</p>
          <p class="date">{{'date range'}}</p>
        </div>
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-shop" viewBox="0 0 16 16">
            <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h1v-5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v5h6V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5M4 15h3v-5H4zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1zm3 0h-2v3h2z"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="filters">
        <p class="filter-text">Service Sold</p>
        <mat-button-toggle-group
          [hideSingleSelectionIndicator]="true" [(ngModel)]="revenueFilter"
          name="revenue filter" aria-label="time stamp">
          <mat-button-toggle value="yearly">Yearly</mat-button-toggle>
          <mat-button-toggle value="monthly">Monthly</mat-button-toggle>
          <mat-button-toggle value="daily">Daily</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="stats">
        <div>
          <p class="amount">{{150}}</p>
          <p class="description">Total Revenue</p>
          <p class="date">{{'date range'}}</p>
        </div>
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="transactions">
    <div class="flex">
      <div class="icon-container">
        <span class="icon-border">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
          </svg>
        </span>
      </div>
      <p class="title">Transactions</p>
  </div>
    <div class="filters">
      <select name="filters" id="filter" [(ngModel)]="filter">
        <option value="all">All</option>
        <option value="pending">Pending</option>
        <option value="completed">Completed</option>
      </select>
      <div class="input">
        <input class="field" [(ngModel)]="search" placeholder="Search" name="search"/>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </span>
      </div>
    </div>
    <table mat-table matTableResponsive [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef>
          <div class="more">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
            </svg>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Title </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.title}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> Username </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.user}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> Phone No. </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction["phone"]}} </td>
      </ng-container>

      <ng-container matColumnDef="bought">
        <th mat-header-cell *matHeaderCellDef> Services Bought </th>
        <td mat-cell *matCellDef="let transaction">{{transaction["bought"]}}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef> Total Amount </th>
        <td mat-cell *matCellDef="let transaction"> {{transaction.amount}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let transaction">
          View detail
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>

<div
  class="drop-zone"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave()"
  (drop)="onDrop($event)"
  (click)="onClick()"
  [ngClass]="{'dragover': dragged}"
>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
    </svg>
  </span>
  <p>Click to Upload </p>
  or drag and drop PDF, DOCX, PNG files
</div>

<div class="file-list" *ngIf="files.length">
  <h3>Uploaded Files:</h3>
  <ul>
    <li *ngIf="file">{{ file.name }} ({{ file.size / 1024 | number: '1.0-0' }} KB)</li>
  </ul>
</div>

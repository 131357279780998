<div class="overlay has-fade" [@detailExpand]="isNavOpen ? 'opened' : 'closed'"></div>
<div class="navigation">
  <div class="logo-section">
    <img class="logo" src="/assets/logo.webp" alt="profile picture">
    <div class="user-text">
      <p class="company">TRI-BROTHERS</p>
      <p class="detail">{{ page === 'FAQ' ? page : page | titlecase }}</p>
    </div>
  </div>
  <div class="flex-container">
    <p class="title">{{ page === 'FAQ' ? page : page | titlecase }}</p>
    <div class="child">
      <button class="notification" [matMenuTriggerFor]="notification" aria-label="notification">
        <span class="icon-container" [matBadge]="notifications.length">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
          </svg>
        </span>
      </button>
      <mat-menu class="notification-container" #notification="matMenu">
        <ng-container *ngFor="let notification of notifications; index as i">
          <div class="notification-item" (click)="viewNotification(notification,  i)">
            <p class="title" >{{ notification.title }}</p>
            <p class="desc" >{{ notification.description }}</p>
          </div>
        </ng-container>
      </mat-menu>
      <button class="menu" [matMenuTriggerFor]="menu">
        <img class="pfp" src="/assets/logo.webp" alt="profile picture">
        <div class="user-text">
          <p class="username">{{username}}</p>
          <p class="detail">{{ isAdmin ? "Administrator" : "User"}}</p>
        </div>
        <span class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="isAdmin" mat-menu-item [routerLink]="['/', 'users', 'create']">Create Account</button>
        <button mat-menu-item (click)="logOut()">Sign Out</button>
      </mat-menu>
      <a id="hamburgerBtn" class="hamburger" (click)="toggleNavbar()">
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>
  </div>
  <div class="header__menu has-fade" [@detailExpand]="isNavOpen ? 'opened' : 'closed'">
    <ng-container *ngIf="isAdmin">
      <p class="nav" [routerLink]="['dashboard']" (click)="toggleNavbar()">Dashboard</p>
      <p class="nav" [routerLink]="['orders']" (click)="toggleNavbar()">Orders</p>
      <p class="nav" [routerLink]="['users']" (click)="toggleNavbar()">Users</p>
      <p class="nav" [routerLink]="['actions']" (click)="toggleNavbar()">Actions</p>
      <mat-divider></mat-divider>
      <p class="nav" [routerLink]="['documents']" (click)="toggleNavbar()">Documents</p>
      <p class="nav" [routerLink]="['notifications']" (click)="toggleNavbar()">Notifications</p>
      <p class="nav" [routerLink]="['financials']" (click)="toggleNavbar()">Financials</p>
      <p class="nav additional" [routerLink]="['/', 'users', 'create']" (click)="toggleNavbar()">Create Account</p>
      <p class="nav additional" (click)="logOut()">Sign Out</p>
    </ng-container>
    <ng-container *ngIf="!isAdmin">
      <p class="nav" [routerLink]="['dashboard']" (click)="toggleNavbar()">Dashboard</p>
      <p class="nav" [routerLink]="['services']" (click)="toggleNavbar()">Services</p>
      <p class="nav" [routerLink]="['documents']" (click)="toggleNavbar()">Documents</p>
      <p class="nav" [routerLink]="['invoices']" (click)="toggleNavbar()">Invoices</p>
      <p class="nav" [routerLink]="['faq']" (click)="toggleNavbar()">FAQ</p>
      <p class="nav" [routerLink]="['notifications']" (click)="toggleNavbar()">Notifications</p>
      <p class="nav additional" (click)="logOut()">Sign Out</p>
    </ng-container>
  </div>
</div>

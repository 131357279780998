import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {DatePipe, NgClass, NgIf, TitleCasePipe} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {DataService} from "../data.service";
import {MatMenuModule} from "@angular/material/menu";
import {Router, RouterLink} from "@angular/router";
import {Order} from "../interfaces";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    NgClass,
    TitleCasePipe,
    FormsModule,
    NgIf,
    MatMenuModule,
    RouterLink,
    DatePipe,
    MatTableResponsiveModule,
    MatPaginator,
    MatPaginatorModule,
  ],
  providers: [DataService],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, AfterViewInit{

  constructor(private dataService: DataService,
              private http: HttpClient,
              private router: Router) { }

  baseUrl: string = environment.baseUrl;
  isAdmin!: boolean;
  search!: string;
  filter: string = 'all';
  displayedColumns: string[] = ['position', 'title', 'user', 'date', 'package', 'status', 'more'];
  dataSource: any;
  unreadOrders: number = 0;
  userCount: number = 0;
  expiringCount: number = 0;
  unfulfilledRequests: number = 0;

  ngOnInit(): void {
    this.dataService.isAdmin$.subscribe(data => {
      this.isAdmin = data;
    });
    if (this.isAdmin) {
      this.http.get<Order[]>(this.baseUrl + `order`).subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
          data.forEach((order) => {
            if (!order.isRead) {
              this.unreadOrders++;
            }
          })
        }
      });
      this.http.get<number>(this.baseUrl + `user/count`).subscribe((data) => {
        if (data) {
          this.userCount = data;
        }
      });
      this.http.get<number>(this.baseUrl + `document/expiring`).subscribe((data) => {
        if (data) {
          this.expiringCount = data;
        }
      });
      this.http.get<number>(this.baseUrl + `request/unfulfilled`).subscribe((data) => {
        if (data) {
          this.unfulfilledRequests = data;
        }
      });
    }
  }

  @ViewChild('order') paginator!: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  selectOrder(order: Order) {
    localStorage.setItem('order', JSON.stringify(order));
    this.router.navigate(['/', 'orders', order.id]).then(()=> {return;});
  }

}

<div class="faqs">
  <p class="header">Frequently Asked Questions?</p>
  <div class="container">
    <ng-container *ngFor="let faq of faqs; index as i;">
      <div class="question"
           [class.expanded-row]="question === question"
           (click)="question = question === faq ? null : faq">
        <p>{{faq.question}}</p>
        <span class="go" [@rotate]="faq == question ? 'rotated' : 'normal'">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
          </svg>
        </span>
      </div>
      <div class="answer" [@expand]="faq == question ? 'show' : 'hide'">
        <p>{{faq.answer}}</p>
      </div>
    </ng-container>
  </div>
</div>

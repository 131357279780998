import {Component, OnInit} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {NgIf} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {Invoice} from "../interfaces";
import {environment} from "../../environments/environment";
import {MatTableResponsiveModule} from "../mat-table-responsive/mat-table-responsive.module";

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    FormsModule,
    MatTableModule,
    NgIf,
    MatTableResponsiveModule
  ],
  templateUrl: './invoices.component.html',
  styleUrl: './invoices.component.scss'
})
export class InvoicesComponent implements OnInit {
  constructor(private http: HttpClient) { }

  baseUrl: string = environment.baseUrl;
  filter: string = 'all';
  search: any;
  userId!: any;

  displayedColumns: string[] = ['id', 'title', 'description', 'date', 'action'];
  dataSource: any;

  ngOnInit(): void {
    this.userId = localStorage.getItem('userId');
    this.http.get<Invoice[]>(this.baseUrl + `invoice/user/${this.userId}`)
      .subscribe((data) => {
        if (data) {
          this.dataSource = new MatTableDataSource(data);
        }
    });
  }

}
